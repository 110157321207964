//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash';
import { mapActions } from 'vuex';

export default {
  props: {
    itemText: { type: Function, default: (e) => e.userName },
    itemValue: { type: Function, default: (e) => e.id },
    clearable: { type: Boolean, default: true },
    hideDetails: { type: Boolean, default: false },
    multiple: { type: Boolean, default: true },
    className: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    value: { type: [Array, Object, Number, Boolean], default: () => [] },
    label: { type: String, default: '' },
    width: { type: String, default: '' },
  },

  data() {
    return {
      searchString: '',
      loading: false,
      items: [],
    };
  },

  computed: {
    style() {
      return {
        width: this.width,
      };
    },

    searchResult() {
      const { items } = this;
      return items;
    },
  },

  watch: {
    searchString(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateSearchString();
      }
    },
  },

  methods: {
    ...mapActions('users', ['getUsersBySearchString']),

    input(value) {
      this.$emit('input', value);
    },

    updateSearchString() {
      const { searchString } = this;
      if (!searchString) return;
      this.loading = true;
      this.loadUsers();
    },

    loadUsers: _.debounce(async function load() {
      const { searchString } = this;
      const { items } = await this.getUsersBySearchString(searchString);
      this.items = [...this.items, ...items];
      this.loading = false;
    }, 500),
  },
};
